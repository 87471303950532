<template>
  <Overview 
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="current_date"
        :sortOrder=-1
        :newButtonName="$t('form.add', [$t(navName)])"
        :selectFields=selectFields
        :filterOptions="filterOptions"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="current_date" :header="$t('overview.date')" :sortable="true" style="width: 120px">
              <template #body="slotProps">
                {{formatDate(slotProps.data.current_date, 'shortDate')}}
              </template>
            </Column>
            <Column field="location.full_name" :header="$t('navigation.locations')" :sortable="false">
              <template #body="slotProps">
                <div class="uploaded-logo uploaded-logo-sm" :style="{'background-image': `url(${slotProps.data.location.icon_url || (slotProps.data.location.franchise ? slotProps.data.location.franchise.icon_url : false)})`}"></div>
                <span>{{slotProps.data.location.full_name}}</span>
                <span v-for="tag in slotProps.data.tags" :key="tag.name" class="pill bg-unidark text-light mx-2">{{tag.name}}</span>
              </template>
            </Column>
            <Column field="location.check_daystates" :header="$t('overview.check')" :sortable="true" style="width: 150px; text-align: center">
              <template #body="slotProps">
                  <i v-if="slotProps.data.location.check_daystates" class="far fa-check text-success"></i>
                  <i v-else class="far fa-times text-danger"></i>
              </template>
            </Column>
            <Column field="status" :header="$t('overview.status')" :sortable="false" style="width: 150px; text-align: center">
              <template #body="slotProps">
                  <div class="text-center" v-html="getStatus(slotProps.data)"
                    :data-bs-toggle="slotProps.data.last_error && slotProps.data.status.includes('error') ? `tooltip` : null" :data-bs-title="slotProps.data.last_error"
                  ></div>
              </template>
            </Column>
            <Column field="profit" :header="$t('overview.income')" :sortable="false" style="width: 150px">
              <template #body="slotProps">
                {{formatCurrency(slotProps.data.total_payment || "0.00", true)}}
              </template>
            </Column>
            <Column field="revenue" :header="$t('overview.revenue')" :sortable="false" style="width: 150px">
              <template #body="slotProps">
                {{formatCurrency(slotProps.data.total_turnover || "0.00", true)}}
              </template>
            </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import dsService from '@/services/DaystateService'
import csService from '@/services/CustomerService'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: dsService,
            itemFormRef: null,
            companyTypes: [],
            selectFields: [],
            navName: 'navigation.daystates',
            filterOptions: [
                {
                    field: 'location.id',
                    nameField: 'full_name',
                    service: csService,
                    name: "Locaties",
                    multiple: true
                }
            ],
        }
    },
    mounted() {
        //this.itemFormRef = this.$refs.ItemForm;
    },
    methods: {
        getStatus(data){
          if(!data.status) return '';
          const statuses = [
            {"name": "ok", "class": "bg-success text-light", "inner": "OK"},
            {"name": "import_pending", "class": "bg-secondary text-light", "inner": "<i class=\"far fa-clock\"></i> import"},
            {"name": "import_warning", "class": "bg-warning text-light", "inner": "<i class=\"far fa-warning\"></i> import"},
            {"name": "import_error", "class": "bg-danger text-light", "inner": "<i class=\"far fa-warning\"></i> import"},
            {"name": "export_pending", "class": "bg-unidark text-light", "inner": "<i class=\"far fa-clock\"></i> export"},
            {"name": "export_warning", "class": "bg-warning text-light", "inner": "<i class=\"far fa-warning\"></i> export"},
            {"name": "export_error", "class": "bg-danger text-light", "inner": "<i class=\"far fa-warning\"></i> export"},
            {"name": "exported", "class": "bg-success text-light", "inner": "<i class=\"far fa-check default-color\"></i> export"}
          ];
          //get status by name
          const status = statuses.find(s => s.name === data.status);
          if(!status) return '';
          return `<span class="pill status-pill ${status.class}">${status.inner}</span>`;
        }
    }
    
}
</script>
<style scoped>
.dlogo{
  width: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.rlogo{
  border-radius: 100px;
}
</style>